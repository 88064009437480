import React, { useRef } from 'react';
import { Link } from 'bb/app/nav';
import { useRouter } from 'bb/app/router';
import { type BookSeries, type Contributor } from 'bb/discovery/book/types';
import { useOwnedEditions } from 'bb/discovery/hooks/owned';
import {
    type BookLightResponse,
    type BookListResponse
} from 'bb/discovery/types';
import { getDiscoveryUrlSlug } from 'bb/discovery/utils/getDiscoveryUrlSlug';
import { useTranslation } from 'bb/i18n';
import { ArrowRightIcon } from 'bb/ui';
import { BookCard } from '../BookCard';
import { Carousel } from '../Carousel';

interface BookCarouselProps {
    title: string | JSX.Element;
    books: BookLightResponse[];
}

export const BookCarousel: React.FC<BookCarouselProps> = ({
    title,
    books = []
}) => {
    const { data: ownedEditions } = useOwnedEditions(
        books.map((book) => [book.audiobookisbn, book.ebookisbn])
    );

    return (
        <Carousel title={title} headerColor="primary-white">
            {books?.map((book) => (
                <BookCard
                    key={`${title}-${book.id}`}
                    book={book}
                    ownedAudiobook={ownedEditions.has(book.audiobookisbn)}
                    ownedEbook={ownedEditions.has(book.ebookisbn)}
                />
            ))}
        </Carousel>
    );
};

interface SeriesBooksCarouselProps {
    series: BookSeries;
    books: BookLightResponse[];
}

export const SeriesBooksCarousel: React.FC<SeriesBooksCarouselProps> = ({
    series = {} as BookSeries,
    books = []
}) => {
    const { routes } = useRouter();
    const urlSlug = getDiscoveryUrlSlug({
        title: series.name,
        id: series.id?.toString()
    });
    const { data: ownedEditions } = useOwnedEditions(
        books.map((book) => [book.audiobookisbn, book.ebookisbn])
    );

    return (
        <Carousel
            title={
                <Link
                    route={routes.series}
                    query={{
                        seriesId: urlSlug
                    }}
                >
                    {series.name}
                    <ArrowRightIcon inline color="primary-white" />
                </Link>
            }
            headerColor="primary-white"
        >
            {books?.map((book) => (
                <BookCard
                    key={`${series.id}-${book.id}`}
                    book={book}
                    ownedAudiobook={ownedEditions.has(book.audiobookisbn)}
                    ownedEbook={ownedEditions.has(book.ebookisbn)}
                />
            ))}
        </Carousel>
    );
};

export interface BookListCarouselProps {
    bookList: BookListResponse;
    showHeader?: boolean;
}

export const BookListCarousel: React.FC<BookListCarouselProps> = ({
    bookList = {} as BookListResponse,
    showHeader = true
}) => {
    const linkRef = useRef<HTMLAnchorElement>(null);
    const { routes } = useRouter();
    const urlSlug = getDiscoveryUrlSlug({
        title: bookList.title,
        id: bookList.id?.toString()
    });
    const { data: ownedEditions } = useOwnedEditions(
        bookList?._embedded.books?.map((book) => [
            book.audiobookisbn,
            book.ebookisbn
        ])
    );

    return (
        <Carousel
            showHeader={showHeader}
            title={
                <Link
                    ref={linkRef}
                    route={routes.bookList}
                    query={{
                        id: urlSlug
                    }}
                    color="primary-white"
                >
                    {bookList.title}
                    <ArrowRightIcon inline color="primary-white" />
                </Link>
            }
            headerColor="primary-white"
        >
            {bookList?._embedded?.books?.map((book, index) => (
                <BookCard
                    key={`${bookList.title}-${book.id}`}
                    book={book}
                    ownedAudiobook={ownedEditions.has(book.audiobookisbn)}
                    ownedEbook={ownedEditions.has(book.ebookisbn)}
                    bookTopListNumber={
                        bookList.rendertype === 'NumberedList'
                            ? index + 1
                            : undefined
                    }
                />
            ))}
        </Carousel>
    );
};

interface AuthorsBooksCarouselProps {
    author: Contributor;
    books: BookLightResponse[];
}

export const AuthorsBooksCarousel: React.FC<AuthorsBooksCarouselProps> = ({
    author = {},
    books = []
}) => {
    const { routes } = useRouter();
    const { t } = useTranslation(['books', 'book']);
    const { data: ownedEditions } = useOwnedEditions(
        books.map((book) => [book.audiobookisbn, book.ebookisbn])
    );

    return (
        <Carousel
            title={
                <Link
                    route={routes.authorPage}
                    query={{
                        id: getDiscoveryUrlSlug({
                            title: author.displayname,
                            id: author.id
                        })
                    }}
                >
                    {t('book:otherBooksBy', {
                        authorName: author.displayname
                    })}
                    <ArrowRightIcon inline color="primary-white" />
                </Link>
            }
            headerColor="primary-white"
        >
            {books?.map((book) => (
                <BookCard
                    key={`${author.displayname}-${book.id}`}
                    book={book}
                    ownedAudiobook={ownedEditions.has(book.audiobookisbn)}
                    ownedEbook={ownedEditions.has(book.ebookisbn)}
                />
            ))}
        </Carousel>
    );
};
