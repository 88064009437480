import React, { type FC, useEffect, useRef } from 'react';
interface CarouselItemProps {
    index: number;
    totalChildren: number;
    children?: React.ReactNode;
}
export const CarouselItem: FC<CarouselItemProps> = ({
    children,
    index,
    totalChildren
}) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!ref.current) return;
        ref.current
            .querySelectorAll(
                '[aria-hidden="false"] a, button, select, input, textarea, [tabindex]'
            )
            .forEach((focusableElement) => {
                focusableElement.setAttribute('tabindex', '0');
            });
    }, []);

    return (
        <div ref={ref} title={`Item ${index} of ${totalChildren}`}>
            {children}
        </div>
    );
};
