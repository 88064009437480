import React, {
    type FC,
    useEffect,
    useId,
    useMemo,
    useRef,
    useState
} from 'react';
import classNames from 'classnames';
import { useWindowWidth } from 'bb/app/useWindowWidth';
import { Text, useTranslation } from 'bb/i18n';
import { type Color } from 'bb/style/types';
import { ArrowLeftIcon, ArrowRightIcon, DeprecatedButton, Flex } from 'bb/ui';
import { debounce, getNextFocusableElement } from 'bb/utils';
import css from './carousel.module.scss';
import { CarouselItem } from './CarouselItem';

interface CarouselProps {
    title: string | JSX.Element;
    showHeader?: boolean;
    headerColor?: Color;
    children?: React.ReactNode;
}

/**
 * This component assumes all children are the same width
 *
 * @param title
 * @returns JSX.Element
 */
export const Carousel: FC<CarouselProps> = ({
    title,
    showHeader = true,
    headerColor = 'primary-black',
    children
}) => {
    const carouselId = useId();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const listContainerRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLDivElement>(null);
    const windowWidth = useWindowWidth();
    const { t } = useTranslation('books');
    const [widthValues, setWidthValues] = useState<
        Record<string, number | null>
    >({
        lastPossibleXPos: null,
        containerWidth: null,
        scrollableWidth: null,
        slideWidth: null
    });
    const [activeButtons, setActiveButtons] = useState({
        left: false,
        right: true
    });

    const memoizedChildren = useMemo(() => {
        const totalChildren = React.Children.count(children);
        return React.Children.map(children, (child, index) => (
            <CarouselItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                index={index + 1}
                totalChildren={totalChildren}
            >
                {child}
            </CarouselItem>
        ));
    }, [children]);

    const hasScrollableContent =
        widthValues.containerWidth &&
        widthValues.scrollableWidth &&
        Math.round(widthValues.containerWidth) <
            Math.round(widthValues.scrollableWidth);

    const calculateWidths = () => {
        if (
            !memoizedChildren?.length ||
            !wrapperRef.current ||
            !listContainerRef.current ||
            !listRef.current
        ) {
            return;
        }

        const { width: widthOfContainer } =
            wrapperRef.current.getBoundingClientRect();

        const { scrollWidth } = listContainerRef.current;

        const firstItem = listRef.current.firstChild as HTMLDivElement;

        const { width: itemWidth } = firstItem.getBoundingClientRect();

        // Since items in the carousel have a 1rem margin we need to take this into consideration when translating the list
        const rootEmValue = parseInt(
            getComputedStyle(document.documentElement).fontSize,
            10
        );

        setWidthValues({
            lastPossibleXPos: scrollWidth - widthOfContainer,
            containerWidth: widthOfContainer,
            scrollableWidth: scrollWidth,
            slideWidth: itemWidth + rootEmValue
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => calculateWidths(), [windowWidth]);

    const move = (dir: 'prev' | 'next') => {
        const { slideWidth, containerWidth } = widthValues;
        if (!slideWidth || !containerWidth || !listContainerRef.current) {
            return;
        }

        /**
         * moveDistance is equal to however many cards that fit the screen minus one.
         * If slide 1,2,3,4 is showing, the next ones will be 4,5,6,7
         */
        const moveDistance =
            slideWidth * Math.floor(containerWidth / slideWidth) - slideWidth;
        const moveBy = dir === 'prev' ? -Math.abs(moveDistance) : moveDistance;

        listContainerRef.current.scrollBy({
            left: moveBy,
            behavior: 'smooth'
        });
    };

    const debouncedScrollHandler = debounce(
        (e: React.UIEvent<HTMLDivElement>) => {
            const scrollVal = (e.target as HTMLDivElement).scrollLeft;
            const { scrollableWidth, containerWidth } = widthValues;
            if (!scrollableWidth || !containerWidth) {
                return;
            }
            if (scrollVal <= 0) {
                setActiveButtons({ left: false, right: true });
            } else if (scrollVal >= scrollableWidth - containerWidth) {
                setActiveButtons({ left: true, right: false });
            } else {
                setActiveButtons({ left: true, right: true });
            }
        },
        50
    );

    const skipList = () => {
        if (wrapperRef.current) {
            const nextFocusableElement =
                getNextFocusableElement<HTMLDivElement>(wrapperRef.current);
            if (nextFocusableElement) nextFocusableElement.focus();
        }
    };

    return (
        <div className={css.base} ref={wrapperRef}>
            <Flex alignItems="center" className={css.header} gap="small">
                {showHeader && (
                    <Text
                        as="h2"
                        size="medium"
                        className={css.title}
                        color={headerColor}
                    >
                        {title}
                    </Text>
                )}
                <DeprecatedButton
                    onClick={skipList}
                    variant="text"
                    inverted
                    className={css.skipBtn}
                >
                    {t('books:skipBookList')}
                </DeprecatedButton>
            </Flex>
            <div
                style={{ position: 'relative' }}
                role="region"
                aria-live="polite"
            >
                {hasScrollableContent && (
                    <button
                        aria-label="Show previous items"
                        aria-controls={carouselId}
                        disabled={!activeButtons.left}
                        className={classNames(css.button, css.leftButton)}
                        type="button"
                        onClick={() => move('prev')}
                    >
                        <ArrowLeftIcon size="large" color="primary-white" />
                    </button>
                )}
                <div
                    className={css.carousel}
                    ref={listContainerRef}
                    onScroll={debouncedScrollHandler}
                >
                    <div
                        ref={listRef}
                        id={carouselId}
                        className={css.contentContainer}
                    >
                        {memoizedChildren}
                    </div>
                </div>
                {hasScrollableContent && (
                    <button
                        aria-label="Show next items"
                        aria-controls={carouselId}
                        disabled={!activeButtons.right}
                        className={classNames(css.button, css.rightButton)}
                        type="button"
                        onClick={() => move('next')}
                    >
                        <ArrowRightIcon size="large" color="primary-white" />
                    </button>
                )}
            </div>
        </div>
    );
};
